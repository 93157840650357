var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-invoice-add-new-customers","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Ajouter un compte ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Numero de compte","label-for":"customer-name"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-nom","name":"register-numeroCompte","state":errors.length > 0 ? false:null,"placeholder":"AZ000000EH09"},model:{value:(_vm.compte.numero_compte),callback:function ($$v) {_vm.$set(_vm.compte, "numero_compte", $$v)},expression:"compte.numero_compte"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Libelle","label-for":"customer-name"}},[_c('validation-provider',{attrs:{"name":"libelle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-libelle","name":"register-libelle","state":errors.length > 0 ? false:null,"placeholder":"NSIA, ECOBANK"},model:{value:(_vm.compte.libelle),callback:function ($$v) {_vm.$set(_vm.compte, "libelle", $$v)},expression:"compte.libelle"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"solde","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"solde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-solde","type":"text","name":"register-solde","state":errors.length > 0 ? false:null,"placeholder":"1000000000"},model:{value:(_vm.compte.solde),callback:function ($$v) {_vm.$set(_vm.compte, "solde", $$v)},expression:"compte.solde"}})]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"taxeValue"}},[_vm._v("Description (facultatif):")]),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Entrer les details du compte","rows":"5","max-rows":"6"},model:{value:(_vm.compte.description),callback:function ($$v) {_vm.$set(_vm.compte, "description", $$v)},expression:"compte.description"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.saveCompte}},[_vm._v(" Ajouter ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }